import React from 'react';
import { Plus } from 'phosphor-react';
import { Spinner } from 'andromeda';
import { useStoreNotes } from './useStoreNotes';
import { useStorePanelContext } from '../context/context';
import { Message } from './Message';
import { AddStoreNoteModal } from './AddStoreNoteModal';

export const StoreNotes: React.FC = () => {
    const { data: storeNotes, isLoading: storeNotesLoading } = useStoreNotes();

    const setAddNoteModalOpened = useStorePanelContext(state => state.setAddNoteModalOpened);

    if (storeNotesLoading) {
        return <Spinner className="!h-72" />;
    }

    const noMessage = !storeNotes || storeNotes.length === 0;

    return (
        <div className="p-7 sm:p-10">
            <button
                type="button"
                className="flex items-center gap-2 rounded-full bg-primaryBlue px-4 py-2 text-sm text-secondary transition-colors hover:bg-blue-700"
                onClick={() => setAddNoteModalOpened(true)}
            >
                <Plus />
                Add Note
            </button>
            {!noMessage && (
                <div className="mt-8 space-y-8">
                    {storeNotes?.map(message => <Message key={message.created_at} {...message} />)}
                </div>
            )}
            {noMessage && (
                <div className="flex h-72 items-center justify-center">
                    <span className="text-primary-70">No notes</span>
                </div>
            )}
            <AddStoreNoteModal />
        </div>
    );
};
