import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Table } from 'andromeda/Table';
import React, { useState } from 'react';
import cx from 'classnames';
import { SpaceportToggle, SpaceportToggleOption } from '@/components/utils/SpaceportToggle';
import { BareCard } from 'andromeda/Card';
import _ from 'lodash';
import { formatCurrency } from '@/utils/numberFormatHelpers';
import styled from '@emotion/styled';
import { AccountSkuMetadata, useStoreForSelectedQueryParam } from '../useStore';

const StyledTable = styled(Table<AccountSkuMetadata>)`
    th {
        button {
            margin-left: 0 !important;
        }
    }
`;

const formatNumber = (value: number, type: 'currency' | 'number') => {
    if (_.isNil(value)) return 'N/A';

    if (type === 'number') return value;

    return formatCurrency(value);
};

const columnHelper = createColumnHelper<AccountSkuMetadata>();

type DateRange = 'LAST_90_DAYS' | 'LAST_365_DAYS';

const TOGGLE_OPTIONS: SpaceportToggleOption<DateRange>[] = [
    {
        value: 'LAST_90_DAYS',
        label: '90 days',
    },
    {
        value: 'LAST_365_DAYS',
        label: '12 months',
    },
];

interface HistoricalSalesProps {
    className?: string;
}

export const HistoricalSales: React.FC<HistoricalSalesProps> = ({ className }) => {
    const [dateRange, setDateRange] = useState<DateRange>('LAST_90_DAYS');

    const { data } = useStoreForSelectedQueryParam();

    const sellinKey = dateRange === 'LAST_90_DAYS' ? 'sellin_last_90_days' : 'sellin_last_365_days';
    const volumeKey = dateRange === 'LAST_90_DAYS' ? 'volume_last_90_days' : 'volume_last_365_days';

    const filteredData = _(data?.account_sku_metadata)
        ?.filter(item => item.product_level === 'subbrand' && !_.isNil(item[volumeKey]))
        .sortBy(volumeKey)
        .reverse()
        .value();

    const columns: ColumnDef<AccountSkuMetadata>[] = [
        columnHelper.accessor('product_level_value', {
            header: 'Sub Brand',
            cell: ({ getValue }) => getValue(),
        }),
        columnHelper.accessor(sellinKey, {
            header: 'Sell-in',
            cell: ({ getValue }) => formatNumber(getValue(), 'currency'),
        }),
        columnHelper.accessor(volumeKey, {
            header: 'Volume (L)',
            cell: ({ getValue }) => formatNumber(getValue(), 'number'),
        }),
    ];

    return (
        <BareCard>
            <div className="flex items-center justify-between">
                <h4 className="text-sm">Historical Sales</h4>
                <SpaceportToggle<DateRange>
                    className="text-sm"
                    options={TOGGLE_OPTIONS}
                    onChange={setDateRange}
                    value={dateRange}
                />
            </div>
            <StyledTable
                className={cx('mt-4', {
                    'min-h-96': filteredData.length === 0,
                })}
                sortable
                data={filteredData}
                columns={columns}
                onSortFn={() => {}}
            />
        </BareCard>
    );
};
