import React from 'react';
import { GlobeHemisphereWest } from 'phosphor-react';
import { Dropdown, DropdownOption } from 'andromeda/Dropdown';
import { STATE_NAME_MAP } from '@/constants/siberia/commercial-impact';
import { useStoresQueryParams } from './useStoresQueryParams';

const STATE_CODES = ['NY', 'FL', 'TX'];
const STATE_OPTIONS: DropdownOption[] = STATE_CODES.map(code => ({
    value: code,
    label: STATE_NAME_MAP[code],
}));

export const StateDropdown: React.FC = () => {
    const { selectedState, setSelectedState } = useStoresQueryParams();

    return (
        <div className="flex items-center gap-1">
            <GlobeHemisphereWest size={18} className="text-primaryBlue" />
            <Dropdown options={STATE_OPTIONS} value={selectedState} onChange={setSelectedState} />
        </div>
    );
};
