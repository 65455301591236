import { IconWrapperButton } from '@/components/utils/IconWrapperButton';
import React from 'react';
import _ from 'lodash';
import { X } from 'phosphor-react';
import { Skeleton } from '@mantine/core';
import cx from 'classnames';
import { useStoresQueryParams } from '../../useStoresQueryParams';
import { useStoreForSelectedQueryParam } from '../../useStore';
import { Actions } from './Actions';
import { StoreProperties } from './StoreProperties';
import { StoreImage } from './StoreImage';

export const StorePanelHeader: React.FC = () => {
    const { setSelectedStoreID } = useStoresQueryParams();

    const { data: storeMetadata, isLoading: storeMetadataLoading } =
        useStoreForSelectedQueryParam();

    const handleClose = () => {
        setSelectedStoreID(undefined);
    };

    return (
        <div>
            <div className="z-10 w-full border-b p-6 sm:px-12 sm:pb-12">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <IconWrapperButton>
                            <X size={18} onClick={handleClose} />
                        </IconWrapperButton>
                        {!storeMetadataLoading && <div>{storeMetadata?.account?.account_name}</div>}
                        {storeMetadataLoading && <Skeleton width={100} height={20} />}
                    </div>
                    <Actions />
                </div>
                {!storeMetadataLoading && (
                    <div className="-mt-2 ml-[26px] text-primary-60">
                        {storeMetadata?.account?.scrapes_neighborhood}
                    </div>
                )}
                {storeMetadataLoading && <Skeleton className="ml-[26px]" width={200} height={17} />}

                <div className="mt-6 flex gap-10">
                    <StoreImage />
                    <StoreProperties
                        className={cx({
                            hidden: storeMetadataLoading,
                        })}
                    />
                    {storeMetadataLoading && (
                        <div className="space-y-2">
                            <Skeleton width={250} height={10} />
                            <Skeleton width={150} height={10} />
                            <Skeleton width={200} height={10} />
                            <Skeleton width={100} height={10} />
                            <Skeleton width={80} height={10} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
