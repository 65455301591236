import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { useAppContext } from '@/contexts/appContext';
import { getStoreNotesKey, StoreNoteItem } from './useStoreNotes';
import { useStoresQueryParams } from '../../useStoresQueryParams';

type CreateStoreNoteResponse = Omit<StoreNoteItem, 'created_at'>;

interface CreateStoreNoteParams {
    type: 'text' | 'image';
    content: string;
}

export const useCreateStoreNote = () => {
    const { user } = useAppContext();
    const { selectedStoreID } = useStoresQueryParams();
    const queryClient = useQueryClient();

    return useMutation<CreateStoreNoteResponse, Error, CreateStoreNoteParams>({
        mutationFn: async ({ content, type }) => {
            const response = await fetch(
                `${PROMO_API_URL}/promo-management/api/promo-management/create_poc_message`,
                {
                    headers: {
                        Authorization: `Bearer ${user.siberiaToken}`,
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        poc_code: selectedStoreID,
                        user: user?.username,
                        content_type: type,
                        content,
                    }),
                },
            );

            if (!response.ok) {
                throw new Error('Failed to create store note');
            }

            await queryClient.invalidateQueries({ queryKey: getStoreNotesKey(selectedStoreID) });

            return response.json();
        },
    });
};
