import cx from 'classnames';
import React, { Fragment } from 'react';
import { Divider } from '@mantine/core';
import { IconWrapperButton } from './IconWrapperButton';

export interface SpaceportToggleOption<T = string> {
    value: T;
    label: React.ReactNode;
    description?: string;
}

interface SpaceportToggleProps<T> {
    options: SpaceportToggleOption<T>[];
    value: T;
    onChange: (value: T) => void;
    className?: string;
}

export const SpaceportToggle = <T extends string | number>({
    options,
    value,
    onChange,
    className,
}: SpaceportToggleProps<T>) => (
    <div className={cx('flex items-center', className)}>
        {options.map((option, index) => (
            <Fragment key={option.value}>
                <IconWrapperButton
                    className={cx({
                        'pr-2': index === 0,
                        'px-2': index > 0 && index < options.length - 1,
                        'pl-2': index === options.length - 1,
                        '!text-primary !fill-primary': option.value === value,
                        '!text-primary-40 !fill-primary-40': option.value !== value,
                    })}
                    description={option.description}
                    onClick={() => onChange(option.value)}
                >
                    {option.label}
                </IconWrapperButton>
                {index < options.length - 1 && <Divider orientation="vertical" color="gray" />}
            </Fragment>
        ))}
    </div>
);
