import { STATE_NAME_MAP } from '@/constants/siberia/commercial-impact';
import _ from 'lodash';
import { StoreItem } from './useStores';

export const OPPORTUNITY_NAME_MAP: Record<string, string> = {
    Volume: 'Volume Opportunity',
    'Point of Distribution': 'POD Opportunity',
    All: 'All',
};

export const STATE_CODES = ['NY', 'FL'];
export const STATES = STATE_CODES.map(code => STATE_NAME_MAP[code]);
export const STATE_NAME_TO_CODE_MAP = _.invert(STATE_NAME_MAP);

export const STORE_TABLE_COL_NAME_MAP: Partial<Record<keyof StoreItem, string>> = {
    opportunity_rank: 'Ranking',
    account_name: 'Account Name',
    neighborhood: 'Neighborhood',
    cc_volume: 'Credit Card Revenue (2023)',
    distance: 'Distance (mi)',
    sell_in_a_year: 'Annual Sell-In ($)',
    sell_in_a_year_precentile: 'Annual Sell-In (Pct.)',
    account_id: 'VIP ID',
};
