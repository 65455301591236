import Color from 'color';
import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

interface BadgeProps {
    color: string;
    className?: string;
}

export const Badge: React.FC<PropsWithChildren<BadgeProps>> = ({ children, color, className }) => (
    <div
        className={cx(
            'flex items-center flex-wrap justify-center py-1 px-2 rounded-2xl text-xxs',
            className,
        )}
        style={{ backgroundColor: Color(color).alpha(0.2).string(), color }}
    >
        {children}
    </div>
);
