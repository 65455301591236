import { formatCurrency } from '@/utils/numberFormatHelpers';
import React from 'react';
import Text from 'andromeda/text';
import { useStoreForSelectedQueryParam } from '../../../useStore';
import { AccountID } from './AccountID';

interface StorePropertiesProps {
    className?: string;
}

export const StoreProperties: React.FC<StorePropertiesProps> = ({ className }) => {
    const { data: storeMetadata } = useStoreForSelectedQueryParam();

    return (
        <div className={className}>
            <div className="col-span-1 md:col-span-3">
                <div className="flex items-center gap-1">
                    <span className="text-xs text-primary-50">Sell-in</span>
                    <span className="text-xxs text-primary-30">(US$ / Percentile)</span>
                </div>
                <div className="mt-2 flex items-center gap-4">
                    <div>
                        <div className="text-xxs text-primary-30">Annual</div>
                        <div className="mt-1 flex items-center gap-1 text-xs text-primary-70">
                            <span>
                                {formatCurrency(
                                    storeMetadata?.account?.account_last_365_days_sellin,
                                )}
                            </span>
                            <span>
                                (
                                {Math.round(
                                    (storeMetadata?.account?.account_last_365_days_sellin_rank ??
                                        0) * 100,
                                )}
                                )
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-xxs text-primary-30">90-day</div>
                        <div className="mt-1 flex items-center gap-1 text-xs text-primary-70">
                            <span>
                                {formatCurrency(
                                    storeMetadata?.account?.account_last_90_days_sellin,
                                )}
                            </span>

                            <span>
                                (
                                {Math.round(
                                    (storeMetadata?.account?.account_last_90_days_sellin_rank ??
                                        0) * 100,
                                )}
                                )
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-4 flex gap-4">
                <AccountID />
                <div>
                    <div className="text-xs text-primary-50">Gainers</div>
                    <div className="mt-1 flex flex-col gap-1 text-xs text-primary-70">
                        {storeMetadata?.subbrand_gainer_drainer?.top_gainer_list.map(item => (
                            <Text type="12Reg" className="!text-navy-solid-70" key={item}>
                                {item}
                            </Text>
                        ))}
                    </div>
                </div>
                <div>
                    <div className="text-xs text-primary-50">Drainers</div>
                    <div className="mt-1 flex flex-col gap-1 text-xs text-primary-70">
                        {storeMetadata?.subbrand_gainer_drainer?.top_drainer_list.map(item => (
                            <Text type="12Reg" className="!text-navy-solid-70" key={item}>
                                {item}
                            </Text>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
