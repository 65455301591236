const toRadians = (degrees: number): number => (degrees * Math.PI) / 180;

const calculateDistanceInMiles = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
): number => {
    const earthRadiusInMiles = 3958.8; // Radius of the Earth in miles

    // Convert latitude and longitude from degrees to radians
    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);

    // Calculate the differences
    const latDiff = lat2Rad - lat1Rad;
    const lonDiff = lon2Rad - lon1Rad;

    // Calculate the distance using Haversine formula
    const a =
        Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDiff / 2) * Math.sin(lonDiff / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusInMiles * c;

    return distance;
};

export { calculateDistanceInMiles };
