export type StorePanelView = 'top-opportunities' | 'store-notes' | 'invoices' | 'store-overview';

export type State = {
    selectedView: StorePanelView;
    addNoteModalOpened: boolean;
    selectedTopOpportunity?: string;
};

export const initialState: State = {
    selectedView: 'top-opportunities',
    addNoteModalOpened: false,
    selectedTopOpportunity: undefined,
};

export const createMethods = (state: State) => ({
    setSelectedView: (view: StorePanelView) => ({ ...state, selectedView: view }),
    setAddNoteModalOpened: (opened: boolean) => ({ ...state, addNoteModalOpened: opened }),
    setSelectedTopOpportunity: (value: string) => ({ ...state, selectedTopOpportunity: value }),
});

export type Methods = ReturnType<typeof createMethods>;
