import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import _ from 'lodash';
import { useStoresQueryParams } from './useStoresQueryParams';

// define api response type
interface MarketIntelligence {
    back_bar: MarketImage[];
    menu: MarketImage[];
}

export interface MarketImage {
    image_url: string;
    note: string;
}

interface UseStoreMarketIntelligenceParams {
    disabled?: boolean;
}

export const useStoreMarketIntelligence = ({ disabled }: UseStoreMarketIntelligenceParams = {}) => {
    const { selectedStoreID } = useStoresQueryParams();

    return useApiQuery<MarketIntelligence>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_market_intelligence`,
        method: 'POST',
        body: selectedStoreID ? { account_id: selectedStoreID } : undefined,
        disabled,
    });
};
