import MultiLevelSelect from 'andromeda/multiSelect/multiLevelSelect';
import React from 'react';
import _ from 'lodash';
import { NewTypeSelectData } from 'andromeda/types/select/newTypeOfSelect';
import { useFilterOptions } from './useFilterOptions';
import { useStoresQueryParams } from '../../useStoresQueryParams';

const defaultOptions: NewTypeSelectData[] = [
    {
        Neighborhood: {
            data: [],
            type: 'multiSelect',
        },
    },
    {
        'Account Type': {
            data: [],
            type: 'multiSelect',
        },
    },
];

export const StoreFilter: React.FC = () => {
    const { data: filterOptions, isLoading: filterOptionsLoading } = useFilterOptions();

    const { filters, setFilters } = useStoresQueryParams();

    return (
        <MultiLevelSelect
            data={filterOptions ?? defaultOptions}
            loading={filterOptionsLoading}
            dropdownClassName="!backdrop-blur-0"
            removeGradient
            onChange={setFilters}
            values={filters}
            maxWidth={350}
            disableGroupMultiSelectedItems
        />
    );
};
