import { Drawer } from '@mantine/core';
import React from 'react';
import { useStoresQueryParams } from '../useStoresQueryParams';
import { StorePanelContent } from './StorePanelContent';
import { StorePanelContextProvider } from './context/context';

const drawerBackground = 'var(--nodes-gradient)';

export const StorePanel: React.FC = () => {
    const { selectedStoreID, setSelectedStoreID } = useStoresQueryParams();

    const handleClose = () => {
        setSelectedStoreID(undefined);
    };

    return (
        <Drawer
            opened={!!selectedStoreID}
            onClose={handleClose}
            position="right"
            size="xl"
            withCloseButton={false}
            styles={{
                body: { minHeight: '100%', padding: 0 },
                content: {
                    background: drawerBackground,
                    overflow: 'hidden !important',
                },
            }}
            zIndex={99999}
        >
            <StorePanelContextProvider>
                <StorePanelContent />
            </StorePanelContextProvider>
        </Drawer>
    );
};
