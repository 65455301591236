import { Stores } from '@/components/Stores';
import { StoresContextProvider } from '@/components/Stores/context/context';
import { PROXIMO_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { UserGuard } from '@/components/utils/UserGuard';

export default function StoresPage() {
    return (
        <ResponsiveLayout
            title="Athena: Store Intelligence"
            className="!mb-0 h-screen overflow-hidden !p-0"
        >
            <StoresContextProvider>
                <UserGuard userGroupWhitelist={PROXIMO_USER_GROUP_WHITELIST}>
                    <Stores />
                </UserGuard>
            </StoresContextProvider>
        </ResponsiveLayout>
    );
}
