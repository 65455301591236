import React from 'react';
import _ from 'lodash';
import { Title } from '@mantine/core';
import dynamic from 'next/dynamic';
import { StorePanel } from './StorePanel';
import { SubbrandSearch } from './SubbrandSearch';
import { TopOpportunities } from './TopOpportunities';
import { StateDropdown } from './StateDropdown';

const StoreMap = dynamic(() => import('./StoreMap').then(mod => mod.StoreMap), { ssr: false });

export const Stores: React.FC = () => (
    <>
        <div className="grid auto-cols-fr gap-4 md:grid-cols-2">
            <div className="flex max-h-screen flex-col p-8 !pr-3 !pt-6">
                <div className="mb-4 flex items-center justify-between">
                    <Title order={2} className="!text-navy-solid-50">
                        Athena: Store Intelligence
                    </Title>
                    <StateDropdown />
                </div>
                <SubbrandSearch />
                <TopOpportunities />
            </div>
            <StoreMap />
        </div>
        <StorePanel />
    </>
);
