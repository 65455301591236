/* eslint-disable @next/next/no-img-element */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { categoryColors } from '@/constants/colors';
import { StoreNoteItem, useStoreNotes } from './useStoreNotes';
import { useGetStoreNotePhoto } from './useGetStoreNotePhoto';

const shuffledColors = _.shuffle(categoryColors);

interface AvatarProps {
    username: string;
}

const Avatar: React.FC<AvatarProps> = ({ username }) => {
    const { data: storeNotes } = useStoreNotes();

    const userList = _.uniqBy(storeNotes, 'user').map(note => note.user);

    const userColorMap: Record<string, string> = userList.reduce(
        (acc, user, index) => {
            acc[user] = shuffledColors[index % categoryColors.length];
            return acc;
        },
        {} satisfies Record<string, string>,
    );

    return (
        <div
            className="flex h-10 min-w-[40px] -translate-y-1/4 items-center justify-center rounded-full text-lg text-white"
            style={{
                backgroundColor: userColorMap[username],
            }}
        >
            {username[0].toUpperCase()}
        </div>
    );
};

export const Message: React.FC<StoreNoteItem> = ({ user, content, created_at, content_type }) => {
    const { data: imageSource } = useGetStoreNotePhoto({
        photoName: content,
        disabled: content_type !== 'image',
    });

    return (
        <div className="flex items-start gap-2 text-sm">
            <Avatar username={user} />
            <div>
                <div className="flex items-center gap-2">
                    <span>{user}</span>
                    <span className="text-primary-50">
                        {moment(created_at).format('DD MMM YYYY HH:mm').toString()}
                    </span>
                </div>
                {content_type === 'image' && imageSource && (
                    <img className="mt-2" src={imageSource} alt={content} />
                )}
                {content_type === 'text' && <div className="mt-2 text-primary-70">{content}</div>}
            </div>
        </div>
    );
};
