import React, { useMemo } from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import _ from 'lodash';
import { SelectsDataType } from 'andromeda/types/select/common';
import { MultiSelect } from 'andromeda';
import { useStoresQueryParams } from './useStoresQueryParams';
import { useOpportunitySubbrands } from './useOpportunitySubbrands';
import { useStores } from './useStores';

export const SubbrandSearch: React.FC = () => {
    const { subbrandSearch, setSubbrandSearchSearch, selectedState, handleURLParamsChange } =
        useStoresQueryParams();
    const { data: subbrands = [] } = useOpportunitySubbrands();
    const { data: stores, isLoading } = useStores({ state: selectedState });

    const options = useMemo<SelectsDataType[]>(() => {
        const subbrandOptions = _(subbrands)
            .sortBy(({ subbrand }) => subbrand)
            ?.map(({ subbrand }) => ({
                value: subbrand,
                label: subbrand,
            }))
            .value();

        const accountOptions = _(stores)
            .sortBy(({ account_name }) => account_name)
            .map(({ account_name, account_id }) => ({
                value: account_id,
                label: account_name ?? account_id,
            }))
            .value();

        if (subbrandSearch && subbrandSearch.length > 0) {
            return subbrandOptions;
        }

        return [
            {
                group: 'SubBrands',
                items: subbrandOptions,
            },
            {
                group: 'Accounts',
                items: accountOptions,
            },
        ];
    }, [subbrands, stores, subbrandSearch]);

    const onChange = (value: string[]) => {
        const isStoreSelected = stores?.some(({ account_id }) => value.includes(account_id));

        if (!isStoreSelected) {
            setSubbrandSearchSearch(value);
            return;
        }

        handleURLParamsChange({
            multipleValues: { selectedStoreID: value, subbrandSearch: undefined },
        });
    };

    const limit = 80;

    return (
        <MultiSelect
            className="mt-2"
            leftSection={<MagnifyingGlass size={16} />}
            placeholder="Search sub brands, or accounts"
            data={options}
            onChange={onChange}
            value={subbrandSearch}
            clearable
            limit={limit}
            width={400}
            loading={isLoading}
        />
    );
};
