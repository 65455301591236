import { useAppContext } from '@/contexts/appContext';
import { useQuery } from '@tanstack/react-query';
import { PROMO_API_URL } from 'buildConfig/processEnv';

interface UseGetStoreNotePhotoParams {
    photoName?: string;
    disabled?: boolean;
}

export const useGetStoreNotePhoto = ({ photoName, disabled }: UseGetStoreNotePhotoParams) => {
    const { user } = useAppContext();

    return useQuery({
        enabled: !disabled,
        queryKey: [`${PROMO_API_URL}/promo-management/api/promo-management/get_photo`, photoName],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                `${PROMO_API_URL}/promo-management/api/promo-management/get_photo`,
                {
                    headers: {
                        Authorization: `Bearer ${user.siberiaToken}`,
                    },
                    method: 'POST',
                    body: JSON.stringify({
                        photo_name: photoName,
                    }),
                    signal,
                },
            );

            if (!response.ok) {
                throw new Error('Failed to get store note photo');
            }

            const blob = await response.blob();
            return URL.createObjectURL(blob);
        },
    });
};
