import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { useAppContext } from '@/contexts/appContext';
import { Opportunity, useStoreForSelectedQueryParam } from '@/components/Stores/useStore';

interface CreateOpportunityFeedbackParams {
    store: Opportunity;
    positive: boolean | null;
    message?: string;
    poc_code: string;
    subbrand: string;
}

type CreateOpportunityFeedbackResponse = CreateOpportunityFeedbackParams;

export const useCreateOpportunityFeedback = () => {
    const { user } = useAppContext();
    const queryClient = useQueryClient();

    const { queryKey: storesQueryKey } = useStoreForSelectedQueryParam();

    return useMutation<CreateOpportunityFeedbackResponse, Error, CreateOpportunityFeedbackParams>({
        mutationFn: async ({ store, positive, message, poc_code, subbrand }) => {
            const url = `${PROMO_API_URL}/promo-management/api/promo-management/create_opportunity_feedback`;

            queryClient.cancelQueries({ queryKey: storesQueryKey });

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${user?.siberiaToken}`,
                },
                body: JSON.stringify({
                    opportunity_payload: { ...store, positive },
                    user: user?.email,
                    positive,
                    message,
                    poc_code,
                    subbrand,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create opportunity feedback');
            }

            await queryClient.invalidateQueries({ queryKey: storesQueryKey });

            return response.json();
        },
    });
};
