import { useStores } from './useStores';
import { useStoresQueryParams } from './useStoresQueryParams';

export const useStoresForProductSearch = () => {
    const { subbrandSearch, selectedState, filters } = useStoresQueryParams();

    return useStores({
        subbrands: subbrandSearch,
        filters,
        state: selectedState,
    });
};
