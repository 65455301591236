import React from 'react';
import { StoresActionsSection } from './StoresActionsSection';
import { StoresTable } from './StoresTable';

export const TopOpportunities: React.FC = () => (
    <>
        <div className="mt-10 flex items-end justify-between">
            <h2 className="mb-[6px] !text-primary-50">Top Opportunities</h2>
            <StoresActionsSection />
        </div>
        <div className="mt-3 w-full grow overflow-x-auto sm:mt-5">
            <StoresTable />
        </div>
    </>
);
