import { useStoresQueryParams } from '@/components/Stores/useStoresQueryParams';
import React from 'react';

interface AccountIDProps {
    className?: string;
}

export const AccountID: React.FC<AccountIDProps> = ({ className }) => {
    const { selectedStoreID } = useStoresQueryParams();

    return (
        <div className={className}>
            <div className="text-xs text-primary-50">VIP ID</div>
            <div className="mt-2 text-xs text-primary-70">{selectedStoreID}</div>
        </div>
    );
};
