import BarChart from '@/components/utils/charts/barChart';
import { ChartData } from 'chart.js';
import { Tooltip } from '@mantine/core';
import React from 'react';
import { primaryColors } from '@/constants/colors';
import Color from 'color';
import cx from 'classnames';
import { BareCard } from 'andromeda/Card';
import _ from 'lodash';
import { useStorePanelContext } from '../context/context';
import { useStoreForSelectedQueryParam } from '../../useStore';

interface ChartLegendItem {
    label: string;
    color: string;
    dashed?: boolean;
}

interface ChartLegendProps {
    legends: ChartLegendItem[];
}

const ChartLegend: React.FC<ChartLegendProps> = ({ legends }) => (
    <div className="flex flex-wrap items-center gap-x-6 gap-y-2">
        {legends.map(legend => (
            <div key={legend.label} className="flex items-center gap-2">
                <div
                    className={cx('h-3 border-2 min-w-[12px] rounded', {
                        'border-dotted': legend.dashed,
                    })}
                    style={{
                        borderColor: legend.color,
                        backgroundColor: legend.dashed
                            ? 'transparent'
                            : Color(legend.color).alpha(0.8).toString(),
                    }}
                />
                <div className="text-xs text-primary-50">{legend.label}</div>
            </div>
        ))}
    </div>
);

const LEGENDS: ChartLegendItem[] = [
    {
        label: 'Opportunity Sell-in Range',
        color: primaryColors.blue,
    },
    {
        label: 'Whole Opportunity',
        color: primaryColors.blue,
        dashed: true,
    },
];

export const OpportunitySize: React.FC = () => {
    const selectedTopOpportunity = useStorePanelContext(state => state.selectedTopOpportunity);

    const { data: { opportunities = [] } = {} } = useStoreForSelectedQueryParam();

    const selectedTopOpportunityData = opportunities?.find(
        item => item.subbrand === selectedTopOpportunity,
    );

    const targetLow =
        /* selectedTopOpportunityData?.target_volume_low */ (selectedTopOpportunityData?.current_volume ??
            0) + 10;
    const targetHight =
        /* selectedTopOpportunityData?.target_volume_hight */ (selectedTopOpportunityData?.current_volume ??
            0) + 20;

    const chartData: ChartData<'bar', any> = {
        labels: [
            ['Store category', 'purchases'],
            ['Proximo sales', 'in category'],
        ],
        datasets: [
            {
                backgroundColor: Color(primaryColors.pink).alpha(0.06).toString(),
                borderColor: Color(primaryColors.pink).alpha(0.3).toString(),
                borderWidth: 1,
                data: [/* selectedTopOpportunityData?.total_volume */ 200, undefined],
                maxBarThickness: 20,
            },
            {
                backgroundColor: Color(primaryColors.purple).mix(Color('#fff'), 0.8).toString(),
                borderColor: Color(primaryColors.purple).mix(Color('#fff'), 0.7).toString(),
                borderWidth: 1,
                data: [undefined, selectedTopOpportunityData?.current_volume],
                maxBarThickness: 20,
                stack: 'promos-sales-in-category',
            },
            {
                backgroundColor: '#f1f1f1',
                borderColor: primaryColors.blue,
                borderWidth: 1,
                data: [undefined, targetLow],
                maxBarThickness: 20,
                stack: 'promos-sales-in-category',
            },
            {
                backgroundColor: Color(primaryColors.blue).mix(Color('#fff'), 0.2).toString(),
                borderColor: primaryColors.blue,
                borderWidth: 1.5,
                data: [undefined, targetHight],
                maxBarThickness: 20,
                stack: 'promos-sales-in-category',
            },
        ],
    };

    const centerOfLabelAnnotation = (() => {
        const low = targetLow ?? 0;
        const high = targetHight ?? 0;
        return low + (high - low) / 2;
    })();

    return (
        <Tooltip label="Pending category ACV / Ground Signal data & business rules from Proximo">
            <BareCard className="flex h-full flex-col">
                <div className="flex items-center justify-between">
                    <div>
                        <h4 className="text-sm">Opportunity Size</h4>
                        <h5 className="mt-1 text-xxs text-primary-30">
                            Category:{' '}
                            {_.startCase(selectedTopOpportunityData?.category?.toLowerCase())}
                        </h5>
                    </div>
                    <div className="text-sm text-primary-80">
                        <div>Sub Brand Fit</div>
                        <div className="font-bold">
                            {selectedTopOpportunityData?.buying_likelihood}th Percentile
                        </div>
                    </div>
                </div>
                <div className="grow p-6 !pl-0">
                    <BarChart
                        data={chartData}
                        legend={{ display: false }}
                        xAxis={{
                            title: { text: 'Cases', display: true, font: { size: 10 } },
                            grid: { display: false },
                            ticks: { color: 'gray' },
                        }}
                        yAxis={{
                            ticks: { font: { size: 10 } },
                            grid: { display: false },
                            border: { color: 'gray', z: 1 },
                            display: true,
                            stacked: true,
                        }}
                        horizontalBars
                        annotation={{
                            annotations: {
                                line1: {
                                    type: 'line',
                                    borderColor: '#bbbbc7',
                                    borderWidth: 1,
                                    value: targetLow,
                                    scaleID: 'x',
                                },
                                line2: {
                                    type: 'line',
                                    borderColor: '#bbbbc7',
                                    borderWidth: 1,
                                    value: targetHight,
                                    scaleID: 'x',
                                },
                                label: {
                                    type: 'label',
                                    xValue: centerOfLabelAnnotation,
                                    yValue: 0.8,
                                    content: `${10} - ${20} more cases`,
                                    display: true,
                                    position: 'center',
                                    color: 'gray',
                                    backgroundColor: 'transparent',
                                    font: { size: 10 },
                                },
                            },
                        }}
                    />
                </div>
                <ChartLegend legends={LEGENDS} />
            </BareCard>
        </Tooltip>
    );
};
