import useRouterQuery from '@/hooks/useRouterQuery';
import _ from 'lodash';
import { useMemo } from 'react';
import { NewSelectPossibleValues } from 'andromeda/types/select/newTypeOfSelect';

interface StoresQueryParams {
    selectedStoreID?: string;
    selectedInvoiceID?: string;
    subbrandSearch?: string[];
    selectedState: string;
    filters?: Record<string, NewSelectPossibleValues>;
}

const nonStringKeys: Array<keyof StoresQueryParams> = ['filters', 'subbrandSearch'];

export const useStoresQueryParams = () => {
    const { router, handleURLParamsChange } = useRouterQuery<keyof StoresQueryParams>();

    const query = useMemo(
        () =>
            _(router.query as unknown as Partial<StoresQueryParams>)
                .defaults({
                    selectedState: 'NY',
                    subbrandSearch: JSON.stringify([]),
                })
                .mapValues((value, key: keyof StoresQueryParams) => {
                    if (!value) return undefined;
                    if (nonStringKeys.includes(key)) {
                        return JSON.parse(value as string) || undefined;
                    }

                    return value;
                })
                .value() as Partial<StoresQueryParams>,
        [router.query],
    );

    const setSelectedStoreID = (selectedStoreID?: string) => {
        handleURLParamsChange({ key: 'selectedStoreID', value: selectedStoreID });
    };

    const setSelectedInvoiceID = (selectedInvoiceID?: string) => {
        handleURLParamsChange({ key: 'selectedInvoiceID', value: selectedInvoiceID });
    };

    const setSubbrandSearchSearch = (subbrandSearch?: string[]) => {
        handleURLParamsChange({ key: 'subbrandSearch', value: JSON.stringify(subbrandSearch) });
    };

    const setSelectedState = (selectedState: string) => {
        handleURLParamsChange({ key: 'selectedState', value: selectedState });
    };

    const setFilters = (filters: Record<string, NewSelectPossibleValues>) => {
        handleURLParamsChange({
            key: 'filters',
            value: JSON.stringify(filters),
        });
    };

    return {
        ...query,
        handleURLParamsChange,
        setSelectedStoreID,
        setSelectedInvoiceID,
        setSubbrandSearchSearch,
        setSelectedState,
        setFilters,
    };
};
