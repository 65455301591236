import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { useStoresQueryParams } from '../../useStoresQueryParams';

export interface StoreNoteItem {
    poc_code: string;
    content_type: string;
    content: string;
    user: string;
    created_at: string;
}

export const getStoreNotesKey = (storeID: string) => [
    `${PROMO_API_URL}/promo-management/api/promo-management/get_poc_message`,
    'POST',
    storeID,
];

export const useStoreNotes = () => {
    const { selectedStoreID } = useStoresQueryParams();

    return useApiQuery<StoreNoteItem[]>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_poc_message`,
        queryKeys: [selectedStoreID],
        method: 'POST',
        body: {
            poc_code: selectedStoreID,
        },
    });
};
