import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatCurrency } from '@/utils/numberFormatHelpers';
import { Tooltip } from '@mantine/core';
import { isMobile } from 'react-device-detect';
import { StoreItem } from '../../useStores';
import { STORE_TABLE_COL_NAME_MAP } from '../../constants';

const columnHelper = createColumnHelper<StoreItem>();

export const columns: ColumnDef<StoreItem>[] = [
    columnHelper.accessor('opportunity_rank', {
        header: (
            <Tooltip
                withinPortal
                label="Ranking of the opportunity size to sell in more of all subbrands at this store, in the next 90 days"
                zIndex={99999}
            >
                <span>{STORE_TABLE_COL_NAME_MAP.opportunity_rank}</span>
            </Tooltip>
        ) as any,
        cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor('account_name', {
        header: STORE_TABLE_COL_NAME_MAP.account_name,
        cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor('neighborhood', {
        header: STORE_TABLE_COL_NAME_MAP.neighborhood,
        cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor('cc_volume', {
        header: STORE_TABLE_COL_NAME_MAP.cc_volume,
        cell: ({ getValue }) => formatCurrency(getValue()),
    }),
    columnHelper.accessor('sell_in_a_year', {
        header: (
            <Tooltip
                label="Total amount that the account has spent with Proximo in the last year"
                zIndex={99999}
            >
                <span>{STORE_TABLE_COL_NAME_MAP.sell_in_a_year}</span>
            </Tooltip>
        ) as any,
        cell: ({ getValue }) => formatCurrency(getValue()),
    }),
    columnHelper.accessor('sell_in_a_year_precentile', {
        header: (
            <Tooltip
                label="Percentile rank for the sell-in amount of the account with Proximo in the last year"
                zIndex={99999}
            >
                <span>{STORE_TABLE_COL_NAME_MAP.sell_in_a_year_precentile}</span>
            </Tooltip>
        ) as any,
        cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor('account_id', {
        header: STORE_TABLE_COL_NAME_MAP.account_id,
        cell: ({ getValue }) => getValue(),
    }),
];

if (isMobile) {
    columns.push(
        columnHelper.accessor('distance', {
            header: STORE_TABLE_COL_NAME_MAP.distance,
            cell: ({ getValue }) => getValue(),
        }),
    );
}
