import styled from '@emotion/styled';
import { Row } from '@tanstack/react-table';
import { Table, useTableState } from 'andromeda/Table';
import React, { useMemo } from 'react';
import cx from 'classnames';
import Pagination from 'andromeda/Pagination';
import { BareCard } from 'andromeda/Card';
import { useGeolocation } from '@uidotdev/usehooks';
import { primaryColors } from '@/constants/colors';
import { Spinner } from 'andromeda';
import { useStoresQueryParams } from '../../useStoresQueryParams';
import { useStoresContext } from '../../context/context';
import { StoreItem } from '../../useStores';
import { useStoresForProductSearch } from '../../useStoresForProductSearch';
import { calculateDistanceInMiles } from '../../storeUtil';
import { columns } from './columns';

const StyledPagination = styled(Pagination)`
    * {
        font-size: 12px !important;
    }
`;

const StyledTable = styled(Table<StoreItem>)`
    th {
        white-space: nowrap;
        button {
            margin-left: 0 !important;
            white-space: normal !important;
            text-align: left !important;
        }
    }
`;

interface StoresTableProps {
    className?: string;
}

export const StoresTable: React.FC<StoresTableProps> = ({ className }) => {
    const { setSelectedStoreID } = useStoresQueryParams();
    const hoveredStoreOnMap = useStoresContext(state => state.hoveredStoreOnMap);
    const setHoveredStoreOnTable = useStoresContext(state => state.setHoveredStoreOnTable);
    const location = useGeolocation();

    const { data: stores, isFetching: storesLoading } = useStoresForProductSearch();

    const filteredTop100Stores = useMemo(() => {
        const top100 = stores?.slice(0, 100);

        if (!location) return top100;

        return top100?.map(store => {
            const distance = calculateDistanceInMiles(
                store.latitude,
                store.longitude,
                location.latitude,
                location.longitude,
            ).toFixed(2);

            return { ...store, distance };
        });
    }, [stores, location]);

    const { tableState, setTableState } = useTableState({
        pagination: {
            pageSize: 20,
            pageIndex: 0,
        },
    });

    const pageIndex = tableState.pagination?.pageIndex ?? 0;
    const pageSize = tableState.pagination?.pageSize ?? 10;

    const onPageChange = (page: number) => {
        setTableState(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                pageIndex: page - 1,
            },
        }));
    };

    if (storesLoading) {
        return <Spinner className="py-20" />;
    }

    return (
        <div className="flex h-full flex-col overflow-y-hidden">
            <BareCard
                className={cx('!p-0 rounded overflow-y-scroll grow', className)}
                onMouseLeave={() => setHoveredStoreOnTable(undefined)}
            >
                <StyledTable
                    columns={columns}
                    data={filteredTop100Stores}
                    stickyHeader
                    sortable
                    onClickRow={(row: Row<StoreItem>) =>
                        setSelectedStoreID(row.original.account_id)
                    }
                    rowClassName={(row: Row<StoreItem>) =>
                        cx('cursor-pointer hover:bg-primary-10', {
                            'bg-primary-10':
                                row.original.account_id === hoveredStoreOnMap?.account_id,
                        })
                    }
                    colClassName="!py-4"
                    tableState={tableState}
                    onTableStateChange={setTableState}
                    onHoverRow={(row: Row<StoreItem>) => setHoveredStoreOnTable(row.original)}
                />
            </BareCard>
            <div className="mx-auto mt-4">
                <StyledPagination
                    page={pageIndex + 1}
                    total={Math.ceil((filteredTop100Stores?.length ?? 0) / pageSize)}
                    onChange={onPageChange}
                />
            </div>
        </div>
    );
};
