import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import styled from '@emotion/styled';
import _ from 'lodash';
import { ResponsiveEllipsisText } from '@/components/utils/ResponsiveEllipsisText';
import { useStorePanelContext } from '../context/context';
import { Badge } from '../../Badge';
import { useStoresQueryParams } from '../../useStoresQueryParams';
import { useStoreForSelectedQueryParam } from '../../useStore';
import { OPPORTUNITY_NAME_MAP } from '../../constants';

const OPPORTUNITY_COLOR_MAP: Record<string, string> = {
    Volume: '#F11B97',
    'Point of Distribution': '#6938B9',
};

const StyledCard = styled.div`
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%),
        linear-gradient(113deg, rgba(249, 249, 249, 0.5) 0%, rgba(240, 234, 243, 0.5) 100%);
    backdrop-filter: blur(8px);
    padding: 8px;
`;

export const TopOpportunities: React.FC = () => {
    const ref = useRef<HTMLDivElement>();
    const { selectedStoreID } = useStoresQueryParams();
    const selectedTopOpportunity = useStorePanelContext(state => state.selectedTopOpportunity);
    const setSelectedTopOpportunity = useStorePanelContext(
        state => state.setSelectedTopOpportunity,
    );

    const { data: { opportunities = [] } = {} } = useStoreForSelectedQueryParam();

    useEffect(() => {
        const isSelectedOpportunityInOpportunities = opportunities.some(
            ({ subbrand }) => selectedTopOpportunity === subbrand,
        );
        if (selectedTopOpportunity && isSelectedOpportunityInOpportunities) return;

        setSelectedTopOpportunity(opportunities?.[0]?.subbrand);
    }, [setSelectedTopOpportunity, selectedStoreID, opportunities, selectedTopOpportunity]);

    return (
        <div className="sticky top-16 z-50 sm:px-12" style={{ background: '#EDECEF' }}>
            <div
                ref={ref}
                className="flex w-full snap-x snap-mandatory items-center gap-2 overflow-x-scroll rounded pb-2 pt-3"
            >
                {opportunities?.map(
                    ({ subbrand, category, price_segmentation, opportunity_type }) => (
                        <StyledCard
                            key={subbrand}
                            id={subbrand}
                            className={cx(
                                'cursor-pointer h-[136px] flex flex-col snap-always snap-start',
                                {
                                    'opacity-100': selectedTopOpportunity === subbrand,
                                    'opacity-60 hover:opacity-80':
                                        selectedTopOpportunity !== subbrand,
                                },
                            )}
                            style={{
                                minWidth: 'calc((100% - 32px) / 5)',
                            }}
                            onClick={() => setSelectedTopOpportunity(subbrand)}
                        >
                            <h5
                                className={cx('text-xs truncate w-24', {
                                    'text-magenta': selectedTopOpportunity === subbrand,
                                    'text-primary-60': selectedTopOpportunity !== subbrand,
                                })}
                            >
                                <ResponsiveEllipsisText tooltipContent={subbrand}>
                                    {subbrand}
                                </ResponsiveEllipsisText>
                            </h5>
                            <div className="mt-1 text-xxs text-primary-50">
                                {_.startCase(category?.toLowerCase())}
                            </div>
                            <div className="mt-1 text-xxs text-primary-50">
                                {_.startCase(price_segmentation?.toLowerCase())}
                            </div>
                            <Badge
                                className="mt-auto"
                                color={OPPORTUNITY_COLOR_MAP[opportunity_type]}
                            >
                                {OPPORTUNITY_NAME_MAP[opportunity_type]}
                            </Badge>
                        </StyledCard>
                    ),
                )}
            </div>
        </div>
    );
};
