import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { useMemo } from 'react';
import { NewTypeSelectData } from 'andromeda/types/select/newTypeOfSelect';
import { useStoresQueryParams } from '../../useStoresQueryParams';

interface FilterOptionsResponse {
    neighborhoods: string[];
    account_types: string[];
}

export const useFilterOptions = () => {
    const { selectedState } = useStoresQueryParams();

    const { data, ...rest } = useApiQuery<FilterOptionsResponse>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_neighborhoods_account_types`,
        method: 'POST',
        body: {
            state: selectedState,
        },
    });

    const filterOptions = useMemo<NewTypeSelectData[]>(() => {
        if (!data) return undefined;

        return [
            {
                Neighborhood: {
                    data: data.neighborhoods,
                    type: 'multiSelect',
                },
            },
            {
                'Account Type': {
                    data: data.account_types,
                    type: 'multiSelect',
                },
            },
        ];
    }, [data]);

    return {
        ...rest,
        data: filterOptions,
    };
};
