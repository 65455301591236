import React from 'react';
import _ from 'lodash';
import { DownloadDataButton } from './DownloadDataButton';
import { StoreFilter } from './StoreFilter';

export const StoresActionsSection: React.FC = () => (
    <div className="space-y-3">
        <DownloadDataButton />
        <StoreFilter />
    </div>
);
