/* eslint-disable @next/next/no-img-element */
import { getGoogleImage } from '@/utils/getGoogleImage';
import React, { useState } from 'react';
import { Skeleton } from '@mantine/core';
import cx from 'classnames';
import { useStoreForSelectedQueryParam } from '../../useStore';
import { useStoresQueryParams } from '../../useStoresQueryParams';

export const StoreImage: React.FC = () => {
    const { data: storeMetadata, isLoading: storeMetadataLoading } =
        useStoreForSelectedQueryParam();
    const { selectedStoreID } = useStoresQueryParams();

    const [imgLoaded, setImgLoaded] = useState(false);

    const loading = storeMetadataLoading || !imgLoaded;

    const imageSource = (() => {
        if (selectedStoreID === '3499790') {
            return 'https://lh3.googleusercontent.com/p/AF1QipNByNkH1lV6snmszYyhRfBhmmDaFOqvgOPgN57k=s1360-w1360-h1020';
        }

        if (selectedStoreID === '4257942') {
            return 'https://lh3.googleusercontent.com/p/AF1QipPJRqtY_yZi48LSqiCpqvv3UQrSzZqwv5OPDCxB=s1360-w1360-h1020';
        }

        return getGoogleImage(storeMetadata?.account?.scrapes_photo);
    })();

    return (
        <>
            <Skeleton
                width={170}
                height={128}
                className={cx({
                    hidden: !loading,
                })}
            />
            <div
                className={cx(
                    'h-32 min-w-[170px] bg-primary-10 flex items-center justify-center text-primary-50 text-2xl',
                    {
                        hidden: loading || imageSource,
                    },
                )}
            >
                No image
            </div>
            <img
                src={imageSource}
                alt={storeMetadata?.account?.account_name}
                className={cx('object-contain h-32', {
                    hidden: loading || !imageSource,
                })}
                onLoad={() => setImgLoaded(true)}
            />
        </>
    );
};
