import { BareCard } from 'andromeda/Card';
import React from 'react';
import { Tabs } from '@mantine/core';
import { StorePanelHeader } from './StorePanelHeader';
import { StorePanelMainView } from './StorePanelMainView';
import { StoreNotes } from './StoreNotes';
import { useStorePanelContext } from './context/context';
import { HistoricalSales } from './HistoricalSales';

export const StorePanelContent: React.FC = () => {
    const selectedView = useStorePanelContext(state => state.selectedView);
    const setSelectedView = useStorePanelContext(state => state.setSelectedView);

    return (
        <BareCard className="relative h-screen overflow-y-scroll !p-0">
            <StorePanelHeader />
            {selectedView !== 'store-notes' && (
                <Tabs
                    classNames={{ tabLabel: '!text-lg' }}
                    value={selectedView}
                    onChange={setSelectedView}
                >
                    <div
                        className="sticky top-0 z-[60] pt-4 sm:px-12"
                        style={{ background: '#EDECEF' }}
                    >
                        <Tabs.List>
                            <Tabs.Tab value="top-opportunities">Top Opportunities</Tabs.Tab>
                            <Tabs.Tab value="store-overview">Store Overview</Tabs.Tab>
                        </Tabs.List>
                    </div>
                    <Tabs.Panel value="top-opportunities">
                        <StorePanelMainView />
                    </Tabs.Panel>
                    <Tabs.Panel value="store-overview">
                        <div className="pt-8 sm:px-12">
                            <HistoricalSales />
                        </div>
                    </Tabs.Panel>
                </Tabs>
            )}
            {selectedView === 'store-notes' && <StoreNotes />}
        </BareCard>
    );
};
