import { Input, Modal } from '@mantine/core';
import { Button } from 'andromeda/Button';
import { PaperPlaneRight, ThumbsDown, ThumbsUp } from 'phosphor-react';
import React from 'react';
import cx from 'classnames';

interface FeedbackModalProps {
    modalOpened: boolean;
    onClose: () => void;
    onSubmit: () => void;
    feedbackText: string;
    setFeedbackText: (value: string) => void;
    stagedFeedback: boolean | undefined;
    isPending?: boolean;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
    feedbackText,
    setFeedbackText,
    modalOpened,
    onClose,
    onSubmit,
    stagedFeedback,
    isPending,
}) => (
    <Modal opened={modalOpened} onClose={onClose} title="Submit feedback" zIndex={9999999}>
        <div className="flex items-center justify-center p-6">
            <div className="flex items-center gap-4">
                <div
                    className={cx('p-1 rounded', {
                        'bg-primary-20': stagedFeedback === true,
                    })}
                >
                    <ThumbsUp size={24} />
                </div>
                <div
                    className={cx('p-1 rounded', {
                        'bg-primary-20': stagedFeedback === false,
                    })}
                >
                    <ThumbsDown size={24} />
                </div>
            </div>
        </div>
        <Input
            placeholder="Anything more you can tell us?"
            value={feedbackText}
            onChange={e => setFeedbackText(e.target.value)}
        />
        <Button
            containerClassName="mt-4 mx-auto"
            variant="primary"
            onClick={onSubmit}
            loading={isPending}
        >
            <div className="flex items-center gap-2">
                Send Feedback
                <PaperPlaneRight size={18} />
            </div>
        </Button>
    </Modal>
);
