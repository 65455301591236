import styled from '@emotion/styled';
import { Button } from 'andromeda/Button';
import React from 'react';
import { downloadCSV } from '@/components/utils/downloadCSV';
import _ from 'lodash';
import { DownloadSimple } from 'phosphor-react';
import { StoreItem } from '../../useStores';
import { useStoresForProductSearch } from '../../useStoresForProductSearch';
import { STORE_TABLE_COL_NAME_MAP } from '../../constants';

const downloadKeys: (keyof StoreItem)[] = [
    'opportunity_rank',
    'account_name',
    'neighborhood',
    'cc_volume',
    'sell_in_a_year',
    'sell_in_a_year_precentile',
    'account_id',
];

export const DownloadDataButton: React.FC = () => {
    const { data: stores } = useStoresForProductSearch();

    const downloadFn = () => {
        downloadCSV({
            data: stores.map(store => _.pick(store, downloadKeys)) ?? [],
            filename: 'stores',
            options: {
                keysOverride: STORE_TABLE_COL_NAME_MAP,
            },
        });
    };

    return (
        <Button
            onClick={downloadFn}
            disabled={!stores || stores.length === 0}
            variant="secondary"
            containerClassName="hidden sm:inline-block ml-auto"
            className="group"
            leftSection={
                <DownloadSimple
                    size={16}
                    className="text-primaryBlue group-hover:text-white group-disabled:text-navy-solid-50"
                />
            }
        >
            Download Data
        </Button>
    );
};
