import useApiQuery from '@/hooks/useApiQuery';
import { NewSelectPossibleValues } from 'andromeda/types/select/newTypeOfSelect';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import _ from 'lodash';

export interface StoreItem {
    account_id: string;
    account_name: string;
    account_type: string;
    latitude: number;
    longitude: number;
    neighborhood: string;
    propensity: number;
    opportunity_rank: number;
    sell_in_a_year: number;
    sell_in_a_year_precentile: number;
    distance: string;
    cc_volume: number;
}

export interface OpportunityItem extends StoreItem {
    subbrand: string;
    category: string;
    price_segmentation: string;
    opportunity_size: string;
    buying_likelihood: number;
    total_volume: number;
    current_volume: number;
    explainability: string[];
    target_volume_high: number;
    target_volume_low: number;
    opportunity_type: string;
    positive?: boolean | null;
    distance: string;
}

interface UseStoresParamsForProduct {
    subbrands?: string[];
    state?: string;
    account_id?: string;
    filters?: Record<string, NewSelectPossibleValues>;
    disabled?: boolean;
}

export const useStores = ({ disabled, filters, ...params }: UseStoresParamsForProduct) =>
    useApiQuery<StoreItem[]>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_top_opportunities`,
        queryKeys: _.map(params, (value, key) => `${key}=${value}`),
        method: 'POST',
        body: {
            ...params,
            neighborhoods: filters?.Neighborhood?.value,
            account_types: filters?.['Account Type']?.value,
        },
        disabled,
    });
