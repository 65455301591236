import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { StoreMetadataResponse } from './types';
import { useStoresQueryParams } from '../useStoresQueryParams';

interface UseStoreParams {
    storeID?: string;
    subbrands?: string[];
}

export const useStore = ({ storeID, subbrands }: UseStoreParams = {}) => {
    const { data, ...res } = useApiQuery<StoreMetadataResponse>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_store_context`,
        method: 'POST',
        body: {
            account_ids: [storeID],
            target_subbrands: subbrands,
        },
        disabled: !storeID,
    });

    return {
        ...res,
        data: data?.[storeID],
    };
};

export const useStoreForSelectedQueryParam = () => {
    const { selectedStoreID, subbrandSearch } = useStoresQueryParams();

    return useStore({ storeID: selectedStoreID, subbrands: subbrandSearch });
};
