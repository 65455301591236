import cx from 'classnames';
import { FileInput, Input, Modal } from '@mantine/core';
import { Button } from 'andromeda/Button';
import React, { useState } from 'react';
import { toBase64 } from '@/utils/toBase64';
import styled from '@emotion/styled';
import { useStorePanelContext } from '../context/context';
import { useCreateStoreNote } from './useCreateStoreNote';

const StyledFileInput = styled(FileInput)`
    button {
        span {
            color: white !important;
        }
        svg {
            color: white !important;
        }
        :hover {
            background-color: transparent !important;
        }
    }
`;

export const AddStoreNoteModal: React.FC = () => {
    const [storeNote, setStoreNote] = useState('');
    const [storeNoteImage, setStoreNoteImage] = useState<File | undefined>();

    const addNoteModalOpened = useStorePanelContext(state => state.addNoteModalOpened);
    const setAddNoteModalOpened = useStorePanelContext(state => state.setAddNoteModalOpened);

    const { mutateAsync: createStoreNote, isPending: isLoadingCreateStoreNote } =
        useCreateStoreNote();

    const onClose = () => {
        setAddNoteModalOpened(false);
        setStoreNote('');
        setStoreNoteImage(undefined);
    };

    const onStoreNoteAdd = async () => {
        const base64 = await toBase64(storeNoteImage);

        const base64Formatted = base64?.split('base64,')?.[1];

        await createStoreNote({
            type: storeNoteImage ? 'image' : 'text',
            content: storeNoteImage ? base64Formatted : storeNote,
        });
        onClose();
    };

    return (
        <Modal opened={addNoteModalOpened} onClose={onClose} title="Add Note" zIndex={9999999}>
            <Input
                placeholder="Leave your note here..."
                onChange={e => setStoreNote(e.target.value)}
                disabled={isLoadingCreateStoreNote || !!storeNoteImage}
            />
            <div className="mt-4 flex items-center justify-end gap-2">
                <StyledFileInput
                    variant="unstyled"
                    size="xs"
                    className={cx('bg-primaryBlue hover:bg-blue-500 py-1 px-3 rounded-full', {
                        '!bg-blue-400': isLoadingCreateStoreNote,
                    })}
                    classNames={{
                        input: '!text-white disabled:bg-blue-400 !border-none',
                    }}
                    accept="image/*"
                    placeholder="Upload image"
                    clearable
                    onChange={setStoreNoteImage as any}
                    value={storeNoteImage}
                    disabled={isLoadingCreateStoreNote}
                />
                <Button onClick={onStoreNoteAdd} loading={isLoadingCreateStoreNote}>
                    Submit
                </Button>
            </div>
        </Modal>
    );
};
