import React from 'react';
import cx from 'classnames';
import { LockSimple, Notepad } from 'phosphor-react';
import StyledTooltip from '@/components/utils/styledTooltip';
import { useStorePanelContext } from '../context/context';

const StyledButton: React.FC<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ className, ...props }) => (
    <button
        type="button"
        className={cx(
            'flex items-center gap-2 py-2 px-4 rounded-full text-sm transition-colors',
            className,
        )}
        {...props}
    />
);

export const Actions: React.FC = () => {
    const selectedView = useStorePanelContext(state => state.selectedView);
    const setSelectedView = useStorePanelContext(state => state.setSelectedView);

    return (
        <div className="flex items-center gap-2">
            {selectedView !== 'store-notes' && (
                <StyledTooltip label="Coming soon">
                    <span>
                        <StyledButton
                            type="button"
                            className={cx({
                                'text-primary-30': selectedView !== 'invoices',
                                'bg-blue-900 hover:bg-blue-700 text-secondary':
                                    selectedView === 'invoices',
                            })}
                            onClick={() =>
                                setSelectedView(
                                    selectedView !== 'invoices' ? 'invoices' : 'top-opportunities',
                                )
                            }
                            disabled
                        >
                            <LockSimple size={22} />
                            Invoices
                        </StyledButton>
                    </span>
                </StyledTooltip>
            )}
            <StyledButton
                type="button"
                className={cx({
                    'text-primary-70 hover:text-primary': selectedView !== 'store-notes',
                    'bg-[#345DEE1A] hover:bg-[#102b8c35]': selectedView === 'store-notes',
                })}
                onClick={() =>
                    setSelectedView(
                        selectedView !== 'store-notes' ? 'store-notes' : 'top-opportunities',
                    )
                }
            >
                <Notepad size={22} className="text-primaryBlue" />
                Store Notes
            </StyledButton>
        </div>
    );
};
