import { Parser } from 'json2csv';

type HeaderItem = { label: string; value: string };

export const downloadCSV = <T>({
    filename,
    data,
    options = {},
}: {
    data: T[] | Blob;
    filename: string;
    options?: {
        keysOverride?: Partial<Record<keyof T, string>>;
        headersSortingFn?: (a: HeaderItem, b: HeaderItem) => number | string;
        filtersData?: Record<string, string>;
    };
}) => {
    if ((Array.isArray(data) && (!data.length || data.length < 1)) || !data?.slice) return;
    let combinedData = data;
    if (options?.filtersData && Array.isArray(data)) {
        const filtersEntries = Object.entries(options?.filtersData || {}).reduce(
            (acc, [k, v]) => [
                ...acc,
                { '': '', Filters: k, Value: Array.isArray(v) ? v.join(', ') : v },
            ],
            [],
        );

        // ? Adding empty rows
        if (filtersEntries.length > data.length) {
            data.push(...Array(filtersEntries.length - data.length).fill([{}]));
        }
        combinedData = data.map((row, index) => ({
            ...row,
            ...(options?.filtersData
                ? {
                      // ? Empty Column
                      ...(filtersEntries?.[index] || []),
                  }
                : {}),
        }));
    }
    const fields = Object.keys(combinedData?.[0] || {}).map(name => ({
        label: options?.keysOverride?.[name]?.replace(/[\n]/g, ' ') || name,
        value: name,
    }));

    const opts = { fields };

    try {
        const parser = new Parser(opts);
        const csvFile = parser.parse(combinedData);
        const blob =
            data instanceof Blob ? data : new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
