import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Text from 'andromeda/text';
import { Spinner } from 'andromeda';
import { useStorePanelContext } from '../context/context';
import { TopOpportunities } from './TopOpportunities';
import { OpportunitySize } from './OpportunitySize';
import { OpportunityFeedbackSection } from './OpportunityFeedbackSection';
import { useStoresForProductSearch } from '../../useStoresForProductSearch';
import VisualContext from './VisualContext';
import { useStoreForSelectedQueryParam } from '../../useStore';

export const StorePanelMainView: React.FC = () => {
    const selectedTopOpportunity = useStorePanelContext(state => state.selectedTopOpportunity);
    const { data: { opportunities = [] } = {}, isLoading: opportunitiesLoading } =
        useStoreForSelectedQueryParam();

    const selectedTopOpportunityData = opportunities?.find(
        item => item.subbrand === selectedTopOpportunity,
    );

    return (
        <>
            <Spinner
                className={cx('!h-72', {
                    '!hidden': !opportunitiesLoading,
                })}
            />
            <div
                className={cx('sm:pb-12 pb-6', {
                    hidden: opportunitiesLoading,
                })}
            >
                <TopOpportunities />
                <div className="mt-6 px-6 sm:px-12">
                    <ul className="mb-6 flex list-inside flex-col gap-2">
                        {selectedTopOpportunityData?.explainability.map(item => (
                            <li key={item} className="leading-3">
                                <Text type="12Reg" className="mt-1 !text-navy-solid-70">
                                    {item}
                                </Text>
                            </li>
                        ))}
                    </ul>
                    <div className="flex items-center justify-between">
                        <h3 className="text-lg">{selectedTopOpportunityData?.subbrand}</h3>
                        <OpportunityFeedbackSection />
                    </div>
                    <div className="text-sm text-primary-70">
                        Category: {_.startCase(selectedTopOpportunityData?.category?.toLowerCase())}
                    </div>
                    <div className="mt-6 h-72">
                        <OpportunitySize />
                    </div>
                    <VisualContext />
                </div>
            </div>
        </>
    );
};
