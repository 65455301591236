import React, { useState } from 'react';
import { IconWrapperButton } from '@/components/utils/IconWrapperButton';
import { Sparkle, ThumbsDown, ThumbsUp } from 'phosphor-react';
import cx from 'classnames';
import { LoadingOverlay } from '@mantine/core';
import { useStoreForSelectedQueryParam } from '@/components/Stores/useStore';
import { useStorePanelContext } from '../../context/context';
import { useCreateOpportunityFeedback } from './useCreateOpportunityFeedback';
import { useStoresQueryParams } from '../../../useStoresQueryParams';
import { FeedbackModal } from './FeedbackModal';

export const OpportunityFeedbackSection: React.FC = () => {
    const { selectedStoreID } = useStoresQueryParams();
    const selectedTopOpportunity = useStorePanelContext(state => state.selectedTopOpportunity);
    const { data: { opportunities = [] } = {} } = useStoreForSelectedQueryParam();

    const selectedTopOpportunityData = opportunities?.find(
        item => item.subbrand === selectedTopOpportunity,
    );
    const [modalOpened, setModalOpened] = useState(false);
    const [stagedFeedback, setStagedFeedback] = useState<boolean | undefined>(undefined);
    const [feedbackText, setFeedbackText] = useState('');

    const feedback = selectedTopOpportunityData?.positive;

    const { mutate, isPending } = useCreateOpportunityFeedback();

    const createFeedback = async (positive?: boolean) =>
        mutate({
            store: selectedTopOpportunityData,
            message: feedbackText,
            positive,
            poc_code: selectedStoreID,
            subbrand: selectedTopOpportunityData?.subbrand,
        });

    const onClickButton = async (type: 'like' | 'dislike') => {
        if ((feedback === true && type === 'like') || (feedback === false && type === 'dislike')) {
            await createFeedback(undefined);
            return;
        }

        if (type === 'like') {
            setStagedFeedback(true);
        } else {
            setStagedFeedback(false);
        }
        setModalOpened(true);
    };

    const onClose = () => {
        setModalOpened(false);
        setStagedFeedback(undefined);
        setFeedbackText('');
    };

    const onSubmit = async () => {
        await createFeedback(stagedFeedback);
        onClose();
    };

    return (
        <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
                <Sparkle size={16} className="text-primaryBlue" />
                <div className="text-xs text-primary-70">Opportunity Feedback:</div>
            </div>
            <div className="relative flex items-center gap-2 p-1">
                <IconWrapperButton onClick={() => onClickButton('like')}>
                    <ThumbsUp
                        size={18}
                        className={cx({
                            'text-green-600': feedback === true,
                        })}
                    />
                </IconWrapperButton>
                <IconWrapperButton onClick={() => onClickButton('dislike')}>
                    <ThumbsDown
                        size={18}
                        className={cx({
                            'text-orange-600': feedback === false,
                        })}
                    />
                </IconWrapperButton>
                <LoadingOverlay
                    visible={isPending}
                    loaderProps={{ type: 'dots', color: 'gray' }}
                    overlayProps={{
                        color: 'gray',
                        backgroundOpacity: 0.2,
                        blur: 1,
                        radius: 4,
                    }}
                />
            </div>
            <FeedbackModal
                modalOpened={modalOpened}
                onClose={onClose}
                onSubmit={onSubmit}
                feedbackText={feedbackText}
                setFeedbackText={setFeedbackText}
                stagedFeedback={stagedFeedback}
                isPending={isPending}
            />
        </div>
    );
};
