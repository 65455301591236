import { PropsWithChildren, useMemo } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { useMethods } from '../../../hooks/useMethods';
import { createMethods, initialState, Methods, State } from './state';

type StoresContextType = State & Methods;

const StoresContext = createContext<StoresContextType>({
    ...initialState,
    ...createMethods(initialState),
});

export const StoresContextProvider = ({ children }: PropsWithChildren) => {
    const [state, methods] = useMethods(createMethods as any, initialState);

    const value = useMemo(() => ({ ...state, ...(methods as Methods) }), [state, methods]);

    return <StoresContext.Provider value={value}>{children}</StoresContext.Provider>;
};

export const useStoresContext = <T,>(selector: (state: StoresContextType) => T) =>
    useContextSelector(StoresContext, selector);
