import Image from 'next/image';
import Text from 'andromeda/text';
import { Overlay, Portal } from '@mantine/core';
import { useState } from 'react';
import { MarketImage, useStoreMarketIntelligence } from '../../useStoreMarketIntelligence';
import { useGetStoreNotePhoto } from '../StoreNotes/useGetStoreNotePhoto';

interface ImageComponentProps {
    item?: MarketImage;
}

const ImageComponent: React.FC<ImageComponentProps> = ({ item }) => {
    const { data: photo } = useGetStoreNotePhoto({ photoName: item?.image_url });

    const [isFullScreen, setIsFullScreen] = useState(false);

    return (
        <>
            <Image
                src={photo}
                alt={item?.image_url}
                className="cursor-zoom-in"
                width={200}
                height={200}
                onClick={() => setIsFullScreen(true)}
            />
            {isFullScreen && photo && (
                <Portal>
                    <Overlay zIndex={9999999999} backgroundOpacity={0.85} className="relative">
                        <div className="absolute inset-10">
                            <Image
                                src={photo}
                                alt={item?.image_url}
                                fill
                                className="cursor-zoom-out object-contain"
                                onClick={() => setIsFullScreen(false)}
                            />
                        </div>
                    </Overlay>
                </Portal>
            )}
        </>
    );
};

const VisualContext = () => {
    const { data } = useStoreMarketIntelligence();

    const isDataAvailable = data?.menu?.length > 0 && data?.back_bar?.length > 0;

    if (!isDataAvailable) return null;

    return (
        <div className="flex flex-col py-4">
            <h3 className="py-3 text-lg">Visual Context</h3>

            {data?.menu?.length > 0 && (
                <>
                    <h3 className="py-2 text-base">Cocktail Menus</h3>
                    <div className="flex flex-col gap-2">
                        {data?.menu.map(item => (
                            <div key={item.image_url} className="grid grid-cols-3 gap-10">
                                <div className="col-span-1 col-start-1">
                                    <ImageComponent item={item} />
                                </div>
                                <Text
                                    type="12Reg"
                                    className="col-span-2 col-start-2 leading-4 !text-navy-solid-70"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.note ?? '<div></div>',
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}

            {data?.back_bar?.length > 0 && (
                <>
                    <h3 className="py-2 text-base">Back Bar</h3>
                    <div className="flex flex-col gap-2">
                        {data?.back_bar.map(item => (
                            <div
                                key={item.image_url}
                                className="grid auto-cols-fr grid-cols-3 gap-10"
                            >
                                <ImageComponent item={item} />
                                <Text
                                    type="12Reg"
                                    className="col-span-2 leading-4 !text-navy-solid-70"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.note ?? '',
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default VisualContext;
