import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';

export interface OpportunityProduct {
    subbrand: string;
}

export const useOpportunitySubbrands = () =>
    useApiQuery<OpportunityProduct[]>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_opportunity_products`,
        method: 'POST',
    });
