import { StoreItem } from '../useStores';

type SelectedStore = Partial<StoreItem>;

export type State = {
    hoveredStoreOnMap?: SelectedStore & { x: number; y: number };
    hoveredStoreOnTable?: SelectedStore;
};

export const initialState: State = {
    hoveredStoreOnMap: undefined,
    hoveredStoreOnTable: undefined,
};

export const createMethods = (state: State) => ({
    setHoveredStoreOnMap: (store?: SelectedStore) => ({ ...state, hoveredStoreOnMap: store }),
    setHoveredStoreOnTable: (store?: SelectedStore) => ({ ...state, hoveredStoreOnTable: store }),
});

export type Methods = ReturnType<typeof createMethods>;
